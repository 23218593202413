@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";

.companyArea {

  .companyAreaList {
    gap: 1rem;
    margin: 1rem 0;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    justify-items: center;

    max-height: 400px;
    overflow-y: auto;

    .buttonSelect {
      width: 100%;
      padding: 1rem 0.5rem 1rem 0;
      border-radius: 1rem;
      background: $gray-100;
      color: $purple-400;

      strong {
        @include OverflowEllipsis(2);
      }


      &:hover {
        background: $gray-200;
      }

      &.buttonSelected {
        border: 4px solid $medium-blue;

        .buttonBorder {
          border: none
        }
      }


      .buttonBorder {
        @include flexCenter;
        flex-direction: column;
        height: 100%;
        padding: 0.5rem;
        border-left: 6px solid $medium-blue;


        .icon {
          @include flexCenter;
          background: $dark-blue;
          color: $white;
          padding: 0.5rem;
          border-radius: 2000px;
        }
      }
    }
  }

  .buttons {
    @include flexCenter;
  }

  &.dark {
    .companyAreaList {
      .buttonSelect {
        background: $secondary-dark-600;
        color: $white;
      }
    }
  }
}