@import "@assets/styles/utils/colors";

.Home {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    padding: 40px 1rem;
}

.News {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    > div {
        width: 100%;
    }

    &Slider {
        background-color: $dark-blue;
        border-radius: 10px;
        max-width: 75vw;
        padding: 1rem;
        gap: 1rem;
    }

    &Card {
        $border-radius: 10px;
        $font-size: 16px;

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 0;
        width: 100%;
        min-height: 250px;
        max-height: 250px;
        background-color: #041b2b;
        border-radius: $border-radius;

        &Banner {
            width: 100%;
            padding: 0;
            margin: 0;

            img {
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
                width: 100%;
                height: 100%;
            }
        }

        &Info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            flex: 1;
            gap: 5px;
            width: 100%;
            padding: 25px;
        }

        &Title {
            font-size: $font-size;
            font-weight: 600;
            color: #fff;
            margin: 0;
        }

        &Summary {
            font-size: calc(0.8 *#{$font-size});
            color: #aaa;
            font-weight: 500;

            p {
                margin: 0;
            }
        }
    }
}

// .Breadcrumbs {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     min-height: 50px;

//     a {
//         color: $dark-blue;
//         font-weight: 600;
//     }
// }
