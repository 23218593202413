@import "@assets/styles/utils/colors";

.Link {
    font-weight: bold;
    color: $medium-blue;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
