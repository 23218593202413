@import "@assets/styles/utils/colors";

.summaryContainer {
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  background: $secondary-200;

  strong {
    color: $secondary-700;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    span {
      height: 35px;
      width: 35px;
      border-radius: 2000px;
      background: $dark-blue;

      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
    }


  }

  &.dark{
    background: $secondary-dark-600;
    strong{
      color: $white;
    }
  }
}