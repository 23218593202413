@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";

@mixin icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: $white;
  @include flexCenter;
}

.filterContent {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &.dark{
    .cards{
      .card{
        background: $secondary-dark-600;
        border-color: $dark-blue;
      }
    }
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
  gap: 8px;

  align-content: center;
  justify-content: center;

  width: 100%;

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;
    border: 2px solid $dark-gray;
    border-radius: 1rem;
    padding: 1rem;
    width: 150px;
    gap: 1rem;

    cursor: pointer;

    transition: all 0.3s;

    &.pending {

      &:hover,
      &.active {
        border-color: $light-orange;
      }

      .icon {
        background: $light-orange;
      }
    }

    &.completed {

      &:hover,
      &.active {
        border-color: $dark-green;
      }

      .icon {
        background: $dark-green;
      }
    }

    &.review {

      &:hover,
      &.active {
        border-color: $dark-orange;
      }

      .icon {
        background: $dark-orange;
      }
    }

    &.waiting-review {

      &:hover,
      &.active {
        border-color: $in-revision;
      }

      .icon {
        background: $in-revision;
      }
    }

    &.removed {

      &:hover,
      &.active {
        border-color: $red;

      }

      .icon {
        background: $red;
      }
    }


    .headerCard {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon {
        @include icon;
      }

      .quantity {
        @include icon;
        background: $dark-blue;
        font-weight: 700;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .cards {
    grid-template-columns: repeat(auto-fit, minmax(80px, 100px));

    .card {
      height: fit-content !important;
      padding: 0.5rem !important;
      width: 100%;
      max-width: 100px;

      .text {
        display: none;
      }
    }

  }
}