$dark-black: #1d1d1d;
$white: #ffffff;
$black: #000000;
$red: #e75555;
$green: #4caf50;

$light-gray: #f1f1f1;
$dark-gray: #e5e5e5;
$dark-blue: #00679d;
$dark-blue-gray: #6d70a4;
$light-blue: #88b5d7;
$medium-blue: #00acdd;
$light-text: #98989d;
$dark-text: #6c6b6e;

$light-orange: #f1c232;
$light-orange-50: #fdf6d6e3;
$dark-orange: #cb7a10;
$dark-orange-50: #f5b562;
$light-green: #93c9a1;
$dark-green: #287b32;
$dark-green-50: #78c981;
$red-50: #ec9b9b;

$dark-blue-900: #004469;
$dark-blue-50: #71c0eb;
$gray-50: #ececec;
$gray-100: #d8d8d8;
$gray-200: #cccbcb;
$gray-400: #98989d;
$purple-400: #5e587c;

$secondary-dark-600: #2e3646;
$secondary-dark-700: #272e3c;
$secondary-dark-800: #212733;
$secondary-dark-900: #1b222b;


// initialized colors generics name

$not-started: #999999;
$in-revision: #79244B;
$pending: #FDD01D;
$awaiting-review: #195DAE;
$completed: #4fa317;


$secondary-200: #EAE9EE;
$secondary-700: #5E517C;

$tertiary-100: #f1f1f1;