@import "@assets/styles/utils/colors";
.avataProfileAndInfo {
  display: flex;
  align-items: center;
  gap: 1rem;

  p {
    font-size: 14px;
    color: $dark-blue;
  }
}