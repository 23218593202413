@import "@assets/styles/utils/colors";

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.header {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
}

.buttonContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    button[data-active="true"] {
        background-color: black;
    }
}

.resultsList {
    padding-top: 0.5rem;
    height: 100%;
    max-height: 300px;
    overflow-y: auto;
    border-radius: 0.2rem;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;


    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $dark-gray;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    div {
        cursor: pointer;

        &:hover {
            background: $dark-gray;
        }
    }

    &.dark{
        .resultItem{
            background: $secondary-dark-900;
            border-color: $dark-blue;

            &:hover {
                background-color: $secondary-dark-600;
            }
        }
    }
}

.resultItem {
    text-decoration: none;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: $white;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background-color: $dark-gray;
    }
}

@media screen and (max-width: 490px) {
    .buttonContainer {
        flex-direction: column;
    }
}