@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";

.contentModal {
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 850px;
  background: $tertiary-100;

  .headerModal {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid $secondary-700;
    background: $tertiary-100;

    strong {
      font-size: 1.3rem;
      color: $secondary-700;
    }
  }

  .bodyModal {
    width: 100%;
    height: 100%;
    padding-top: 0.5rem;
    max-height: 570px;
    overflow-y: auto;
  }

  .footer{
    padding-top: 0.5rem;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  &.dark{
    background: $secondary-dark-800;
    .headerModal {
      background: $secondary-dark-800;
      border-color: $dark-blue;

      strong{
        color: $white;
      }
    }


  }
}