@import "@assets/styles/utils/colors";

.container {

  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;

  .messages {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;
    max-height: 500px;
    overflow-y: auto;

    .comment {
      display: flex;
      align-items: start;
      gap: 0.5rem;
      width: 100%;

      .date {
        font-size: 12px;
        margin: 0.1rem 0 0 0.3rem;
      }

      .contentMessage {
        padding: 0.5rem;
        border-radius: 0.5rem;
        background: $gray-200;
      }

      img {
        border-radius: 1rem;
        object-fit: cover;
        max-height: 300px;
      }
    }
  }

  .otherDocs {
    display: flex;
    gap: 1rem;
  }

  .prevImg {
    background: $gray-200;
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 100%;
    max-width: 400px;
    height: 300px;
    margin: 0 auto;
    position: absolute;
    bottom: 56px;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.5rem;
    }
  }

  .inputFile {
    position: absolute;
    height: 30px;
    width: 30px;
    opacity: 0;
    cursor: pointer;
  }

  .inputArea {
    display: flex;
    gap: 0.5rem;
  }
}