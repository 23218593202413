@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";

.detailTask {
  background: $white;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 800px;

  max-height: 600px;
  overflow-y: auto;

  .header {
    @include flexCenter;
    justify-content: space-between;
    padding-bottom: 1rem;

  }

  .details {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}