@import "@assets/styles/utils/colors";

.Form {
    border: 0.5px solid $light-text;
    border-radius: 5px;
    padding: 2rem;

    &Footer {
        button {
            min-width: 10rem;
        }
    }
}