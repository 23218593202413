.Spinner {
    display: none;

    &[data-loading="true"] {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    &[data-full-page="true"] {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        
        z-index: 100000;
        background-color: transparent;
        backdrop-filter: blur(6px);
        filter: blur(3px);
    }
}
