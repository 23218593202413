@import "@assets/styles/utils/colors";

.title {
  background: $dark-blue !important;
  color: $white !important;
  cursor: default !important;

  &:hover {
    background: $dark-blue !important;
    color: $white !important;
  }
}