@import "@assets/styles/utils/colors";

.sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    gap: 8px;
    padding: 0 1rem;

    position: fixed;
    top: 0;
    left: 0;

    .LogoFigure {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .LogoImg {
        // width: 220px;
        height: 120px;
    }

    &.dark{
        .ModuleItem{
            background-color: $secondary-dark-600;

            &[data-active="true"] {
                background-color: $dark-blue;
                .ModuleItemLabel {
                    color: #fff;
                    font-weight: 600;
                }
        
                svg {
                    color: $dark-blue;
                }
            }
        }
    }
}
.ModuleList {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.ModuleItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    background-color: $dark-gray;
    padding: 15px;
    border-radius: 10px;
    color: $light-text;
    width: 220px;
    font-size: 14px;
    cursor: pointer;

    &,
    * {
        transition: color background-color 0.5s ease-in-out;
    }

    &:not([data-active="true"]):hover svg {
        color: $dark-blue;
    }

    &[data-active="true"] {
        background-color: $dark-blue;
        .ModuleItemLabel {
            color: #fff;
            font-weight: 600;
        }

        svg {
            color: $dark-blue;
        }
    }
}

.ModuleItemIcon {
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    padding: 6px;

    svg {
        color: $light-text;
    }
}

.ModuleItemLabel {
    color: $light-text;
}

@media screen and (max-width: 768px) {
    .sidebar {
        display: none;
    }
}
