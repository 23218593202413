@import "@assets/styles/utils/colors";

.Message {

    &[data-variant="success"] {
        color: $green;
    }
    
    &[data-variant="error"] {
        color: $red;
    }
}
