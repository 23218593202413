.Table {
    width: 100%;

    &Label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;
    }
}
