.ql-toolbar {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.ql-tooltip {
	max-width: 250px;
	z-index: 9999;
}

.ql-editor {
	padding: 0;
}

.quill {
	min-height: 50px;

	p {
		margin-top: 0 !important;
	}
}
