@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";

.News {
    @include flexCenter;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    $gap: 14px;
    $mobile-gap: 10px;


    >div {
        width: 100%;
    }

    &NothingFound {
        @include flexCenter;
        height: 150px;

        p {
            font-size: 20px;
            font-weight: bold;
            color: $medium-blue;
        }
    }

    &Slider {
        background-color: $dark-blue;
        border-radius: 10px;
        width: fit-content;
        gap: $gap;
        padding: 1rem;

        border: 8px solid $dark-blue;

        // @media ((min-width: 401px) and (max-width:700px)) {
        //     padding: $mobile-gap;
        //     gap: $mobile-gap;
        // }

        // @media ((min-width: 200px) and (max-width:700px)) {
        //     max-width: calc(100vw - 32px - 2 * 1rem) !important;
        // }

        // @media ((min-width: 701px) and (max-width:1000px)) {
        //     max-width: calc(75vw - 32px - 2 * 1rem) !important;
        // }
    }

    &Card {
        $border-radius: 10px;
        $font-size: 16px;
        $card-height: 250px;
        $mobile-card-height: 350px;

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        width: 100%;
        max-width: 250px;
        height: 250px;
        background-color: $dark-black;
        border-radius: $border-radius;
        cursor: pointer;
        transition: transform 0.5s ease-in-out;

        &:hover {
            transform: scale(1.05);
        }

        &Banner {
            width: 100%;
            height: 120px;

            img {
                border-radius: $border-radius $border-radius 0 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &Info {
            gap: 8px;
            width: 100%;
            padding: 16px;
            display: flex;
            flex-direction: column
        }

        &Title {
            font-size: $font-size;
            font-weight: 600;
            color: #fff;
            margin: 0;
            @include OverflowEllipsis(2);
        }

        &Summary {
            font-size: calc(0.8 *#{$font-size});
            color: #aaa;
            font-weight: 500;
            @include OverflowEllipsis(3);

            p {
                margin: 0
            }
        }
    }

    &.dark {
        .Breadcrumbs {
            color: $white;

            .titles {
                color: $white;
            }

            strong {
                color: $white;
            }
        }
    }
}

.Breadcrumbs {
    @include flexCenter;
    min-height: 50px;

    p {
        @include OverflowEllipsis(1);

        @media ((min-width: 401px) and (max-width:1000px)) {
            max-width: 500px;
        }

        @media (min-width: 1001px) {
            max-width: 600px;
        }
    }

    a {
        color: $dark-blue;
        font-weight: 600;
    }
}

@media ((min-width: 200px) and (max-width:400px)) {
    .Breadcrumb {
        padding-left: 25px;
    }

}