@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";

.cancelIndicator {
  width: 100%;
  border: 1px solid $red;
  @include flexCenter;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: $red;
  position: relative;
  font-weight: bold;

  .icon {
    @include flexCenter;
    background-color: $red;
    color: $white;
    padding: 0.3rem;
    height: 25px;
    width: 25px;
    border-radius: 2000px;
    position: absolute;
    top: -12px;
  }
}