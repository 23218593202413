@import "@assets/styles/utils/colors";

.Form {
    border: 0.5px solid $light-text;
    border-radius: 5px;

    &Header {
        padding: 2rem;
        border-bottom: 1px solid $light-text;
        padding-bottom: 2rem;
        gap: 1rem;
        flex-wrap: wrap;
    }

    &Footer {
        padding: 2rem;
        padding-top: 1rem;

        button {
            min-width: 12rem;
        }
    }
}