.CustomRoute {
    display: flex;
    width: 100%;
    flex: 1;

    &[data-logged-in="true"] {
        padding-left: 250px;

        @media screen and (max-width: 768px) {
            padding-left: 0;
            padding-bottom: 2rem;
        }
    }
}
