@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";

@mixin flexGap {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
}

@mixin span {
  border-radius: 200px;
  width: 30px;
  height: 30px;
  font-size: 16px;
}

.container {
  width: 100%;
  padding: 0 1rem 2rem 1rem;

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;

    .selectBusinessArea {
      display: flex;
      gap: 1rem;

      .areaSelectButton {
        width: 100%;
        display: flex;
        gap: 1rem;
      }
    }

    .filterType {
      display: flex;
      gap: 1rem;

      .cardFilterType {
        padding: 0.8rem 1rem;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        background: $white;
        cursor: pointer;
        transition: all 0.3s;
        width: 100%;


        &:hover {
          background: $dark-gray;
        }

        &.active {
          background: $dark-blue;
          color: $white;

          span {
            background: $white;
            color: $dark-blue;
          }
        }

        p {
          display: flex;
          flex-direction: column;
        }

        span {
          @include flexCenter;
          @include span;
          background: $dark-blue;
          color: $white;
        }
      }
    }

    .filterStep {
      @include flexGap;
      background: $white;
      padding: 0.5rem;
      gap: 0.5rem;
      border: 2px solid $dark-blue;


      .cardStep {
        @include flexGap;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;
        cursor: pointer;
        width: 100%;

        &.active {
          background-color: $dark-gray;
        }

        &:hover {
          background: $dark-blue;
          color: $white;
        }

        p {
          display: flex;
          flex-direction: column;
        }

        span {
          @include span;
          @include flexCenter;

          &.pending {
            background: $light-orange-50 ;
            color: $light-orange;
          }

          &.completed {
            background: $dark-green-50;
            color: $dark-green;
          }

          &.review {
            background: $dark-orange-50;
            color: $dark-orange;
          }

          &.waiting-review {
            background: $dark-blue-50;
            color: $dark-blue;
          }

          &.removed {
            background: $red-50;
            color: $red;
          }

        }
      }
    }

    &.dark {

      .cardFilterType {
        background: $secondary-dark-600;

        &:hover {
          background: $secondary-dark-800;
        }
      }

      .filterStep {
        background: $secondary-dark-600;

        .cardStep {
          &.active {
            background: $secondary-dark-800;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {

  .filterType {
    gap: 0.5rem !important;
    text-align: center;

    .cardFilterType {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 909px) {
  .filterStep {
    min-width: 100%;
    gap: 0.5rem !important;

    .cardStep {
      font-size: 14px;
      width: 100%;
      gap: 0.5rem !important;
      padding: 0.5rem !important;

      span {
        font-size: 10px !important;
        height: 20px !important;
        width: 20px !important;
      }
    }
  }
}

@media screen and (max-width: 880px) {
  .filterType {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);

    .cardFilterType {
      flex-direction: row;
      align-items: center;
    }
  }
}



@media screen and (max-width: 525px) {

  .selectBusinessArea {
    flex-direction: column;
  }

  .filterType {

    display: flex !important;
    flex-direction: column;

    .cardFilterType {
      padding: 0.5rem !important;
      font-size: 14px;
    }
  }


  .filterStep {

    .cardStep {
      p {
        display: none !important;
      }
    }
  }
}

@media screen and (max-width: 450px) {

  .filterType {
    flex-direction: column;

    .cardFilterType {
      flex-direction: row;
      justify-content: center;
    }
  }
}