@import "@assets/styles/utils/colors";

.textAreaComponent {
    width: 100%;
    min-height: 100px;
    background-color: $white;

    resize: none;
    padding: 0.9rem;
    border-radius: 0.3rem;
    outline: none;
    border: none;

    box-shadow: inset 0 0 8px #00000034;
    transition: all 0.3s;

    &[action-error="error"] {
        box-shadow: inset 0 0 8px #e72f2ffa !important;
    }

    &.dark{
        background: $secondary-dark-600;
        color: $white;
    }
}