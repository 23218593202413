@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .contentColuns {
    display: flex;
    gap: 1rem;

    .content {
      width: 100%;
      height: 100%;
      max-height: 525px;
      overflow-y: auto;
    }

    .contentRight {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;
      color: $secondary-700;

      .contentInfoExecutionFlow {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        h3 {
          font-size: 14px;
        }

        .details {

          p {
            strong {
              margin-right: 0.2rem;
            }

            &:last-child {
              margin-top: 0.5rem;
            }
          }
        }

        .gabarito {
          margin-bottom: 2rem;
          display: flex;
          gap: 0.5rem;

          div {
            display: flex;
            align-items: center;
            gap: 0.2rem;

            &::before {
              content: "";
              height: 12px;
              width: 12px;
              border-radius: 200px;
              background: $not-started;
            }

            &:nth-child(2)::before {
              background-color: $awaiting-review;
            }

            &:nth-child(3)::before {
              background-color: $in-revision;
            }

            &:nth-child(4)::before {
              background-color: $pending;
            }

            &:last-child::before {
              background: $completed;
            }

            p {
              font-size: 10px;
            }
          }
        }



      }
    }
  }

  .areaButton {
    display: flex;
    align-items: center;
    gap: 1rem;

    &[media-type="mobile"] {
      display: none;
    }

  }


  &.dark{

    .contentRight{
      color: $white;
    }
  }
}

@media screen and (max-width: 590px) {
  .gabarito {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(100px, 105px));
  }

  .content {
    max-height: 100px !important;
  }
}

@media screen and (max-width: 1024px) {
  .contentColuns {
    flex-direction: column-reverse;
  }

  .content {
    max-height: 150px !important;
  }

  .areaButton {
    &[media-type="mobile"] {
      display: flex !important;
    }

    &[media-type="web"] {
      display: none;
    }
  }
}