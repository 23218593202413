@import "@assets/styles/utils/colors";

.myFilesContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    padding: 40px 1rem;
}

.filterContent {

    .filterHeader {
        display: flex;
        margin-bottom: 0.5rem;
    }

    .filter {
        display: none;

        &[visible-filter="active"] {
            display: block;
        }

        &[visible-filter="disable"] {
            display: none;
        }
    }
}

@media screen and (min-width: 768px) {

    .filterHeader {
        display: none !important;
    }

    .filter {
        display: block !important;

        &[visible-filter="disable"] {
            display: block;
        }

        &[visible-filter="active"] {
            display: block;
        }
    }
}