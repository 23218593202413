@import "@assets/styles/utils/colors";

.fileFiltersContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: $dark-text;
}

.filterArea {
    display: flex;
    width: 100%;
    gap: 1rem;

    .contentSelect {
        width: 100%;
        display: flex;
        gap: 1rem;

        button {
            height: 40px;
        }
    }
}

.FileFilters {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    &ActiveFilters {
        text-align: left;
        color: $dark-text;

        a {
            color: $dark-blue;
            font-weight: 600;
            text-decoration: none;
        }
    }
}

@media screen and (max-width: 1180px) {
    .filterArea {
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 470px) {
    .filterArea {
        .contentSelect {
            flex-direction: column;
        }
    }
}