@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";

.menu {

  &.dark {
    .headerMenu {
      color: #f0f0f0;
    }

    .notification {
      color: #f8f8f8a8;
    }
  }
}

.headerMenu {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  border-bottom: 1px solid #4b4b4b71;
  align-items: center;

  .quantityNotify {
    padding: 0.5rem;
    border-radius: 0.5rem;
    background: $dark-blue;
    color: $white;
  }
}

.badgeNotify {
  height: 10px;
  width: 10px;
  background: $dark-blue;
  border-radius: 20px;
  position: absolute;
  top: 6px;
  right: 5px;
}

.notification {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  gap: 1rem;

  .infos {
    display: flex;
    gap: 1rem;

    .text {
      width: 100%;

      p,
      h3 {
        width: 100%;
        max-width: 180px;

        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        font-size: 0.8rem;
        text-transform: lowercase;
      }

      h3 {
        font-size: 0.9rem;
      }
    }
  }

  .date {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
}