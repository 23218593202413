@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";

.taskCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;

  background-color: $white;
  border-left: 0.5rem solid $dark-orange;
  border-radius: 4px;
  padding-left: 1rem;


  &.pending {
    border-color: $light-orange;

    h3 {
      color: $light-orange;
    }
  }

  &.completed {
    border-color: $dark-green;

    h3 {
      color: $dark-green;
    }
  }

  &.review {
    border-color: $dark-orange;

    h3 {
      color: $dark-orange;
    }
  }

  &.waiting-review {
    border-color: $in-revision;

    h3 {
      color: $in-revision;
    }
  }

  &.removed {
    border-color: $red;

    h3 {
      color: $red;
    }
  }

  .protocolArea {
    @include flexCenter;
    justify-content: space-between
  }

  .headerCard {
    margin-bottom: 1rem;

    .areaTitle {
      @include flexCenter;
    }


  }

  .headerCard,
  .mainCard {
    @include flexCenter;
    justify-content: space-between;
  }


  .mainCard {
    align-items: end;
  }

  .taskInfo {
    h3 {
      margin-bottom: 0.5rem;
    }
  }

  .areaButton {
    width: 100%;
    max-width: 300px;

    .expirationStatus {
      border: 1px solid $green;
      padding: 1rem;
      border-radius: 1rem;
      position: relative;
      margin-bottom: 1rem;


      p {
        @include OverflowEllipsis(1);
      }

      &.expiration {
        border-color: $red ;

        .iconInfo {
          background: $red;
        }

        .colorText {
          color: $red;
        }
      }

      .colorText {
        color: $green;

        .strongIndicator {
          color: #121212;
        }
      }

      .iconInfo {
        @include flexCenter;
        border-radius: 200px;
        height: 25px;
        width: 25px;
        background: $green;
        color: $white;

        padding: 0.3rem;
        position: absolute;
        top: -12.5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }


    button {
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
      white-space: nowrap;

      span svg {
        margin-top: -4px;
        font-size: 1rem;
      }
    }
  }

  &.dark {
    background: $secondary-dark-600;

    .areaButton {
      width: 100%;
      max-width: 300px;

      .expirationStatus {
        .strongIndicator {
          color: #ffffff99;
        }
      }
    }
  }

}

@media screen and (max-width: 850px) {
  .areaButton {
    button {
      height: 40px;

      span {
        display: none;
      }

      svg {
        font-size: 18px;
      }

    }
  }
}

@media screen and (max-width: 670px) {
  .mainCard {
    flex-direction: column;
    align-items: start !important;
    gap: 1rem !important;

  }

  .areaButton {
    min-width: 100%;

    button {
      span {
        display: flex;

      }
    }
  }
}