@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";

$border-radius: 5px;
$padding: 1rem;

.NewsPanel {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    border-radius: $border-radius;
    border-color: $dark-gray;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
    width: 100%;

    border-top: 10px solid $dark-blue;

    overflow: hidden;

    .detailsNew {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        gap: 1rem;
        width: 100%;

        img {
            align-self: center;
            object-fit: cover;
            max-width: 600px;
            width: 100%;
        }
    }

    .cardOtherNews {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        position: relative;

        .buttonPanelOtherNews {
            display: block;
            height: 40px;
            width: 40px;
            padding: 0.5rem;
            background-color: $dark-blue;
            color: #fff;
            border-radius: 200px;

            position: absolute;
            left: -60px;
            top: -10px;

            transition: all 0.5s;

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    .panelOtherNews {
        background-color: $dark-black;
        padding: 1rem;
        width: 100%;
        max-width: 350px;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        transition: all 0.5s;

        .newsPanelCard {
            display: flex;
            height: 150px;
            border: 1px solid #aaa;

            .banner {
                width: 100%;
                max-width: 130px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .cardInfo {
                padding: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 1rem;
                flex: 1;

                .cardTitle {
                    font-size: 14px;
                    font-weight: 600;
                    color: #fff;
                    margin: 0;
                    @include OverflowEllipsis(3);
                }

                .cardSummary {
                    font-size: 12px;
                    color: #aaa;
                    font-weight: 500;
                    display: -webkit-box;
                    @include OverflowEllipsis(3);

                    p {
                        margin: 0;
                    }
                }

                a {
                    align-self: center;
                    font-weight: 600;
                }
            }
        }
    }


    &.dark{
        background: $secondary-dark-800;
        border-color: $dark-blue;
    }
}

@media screen and (max-width: 1240px) {
    .NewsPanel {
        position: relative;

        .panelOtherNews {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
        }
    }
}