@import "@assets/styles/utils/colors";

.Calendar {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 40px 1rem;
    width: 100%;

    &.dark {
        .CalendarMain {
            background: $secondary-dark-800;
        }
    }
}

.CalendarMain {
    display: flex;
    flex-direction: column;

    background-color: $white;
    border-radius: 10px;
    width: 100%;
    -webkit-box-shadow: 0px 2px 5px -1px rgba(161, 161, 161, 1);
    -moz-box-shadow: 0px 2px 5px -1px rgba(161, 161, 161, 1);
    box-shadow: 0px 2px 5px -1px rgba(161, 161, 161, 1);
}

.CalendarContent {
    padding: 0.5rem 1rem;
    width: 100%;
    background-color: $dark-blue;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.containerCalendar {
    display: flex;
    padding-right: 1rem;
    gap: 0.5rem;
    width: 100%;
}

.CalendarSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.CalendarTitle {
    color: $light-gray;
}

.MuiDayCalendar-header {
    span {
        color: $dark-blue;
        font-weight: bold;
    }
}

.CalendarSubtitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    font-weight: bold;
    color: $light-text;
    gap: 10px;
    padding-top: 15px;

    svg {
        width: 30px;
        height: 30px;
        color: $dark-blue;
    }
}

.CalendarNotEvents {
    padding: 20px;
    color: $dark-blue;
}

.CalendarEvents {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.CalendarEventsItem {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.2rem;
}


.templates {
    display: flex;
    align-items: center;
    gap: 0.6rem;

    .template {
        display: flex;
        gap: 0.2rem;
        align-items: center;

        font-size: 12px;

        .badge {
            height: 12px;
            width: 12px;
            background: rgb(89, 165, 216);

            &.expirationTask {
                background: rgb(219, 6, 6);
            }

            &.eventsAndExpirationTask {

                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 6px;
                    left: 0;
                    right: 0;
                    background: rgb(219, 6, 6);
                }
            }
        }
    }
}

.cardTask {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

}

@media screen and (max-width: 1024px) {
    .containerCalendar {
        flex-direction: column;
        padding: 1rem;
        justify-content: center;
    }
}

@media screen and (max-width: 388px) {
    .MuiDayCalendar-header {
        width: 100% !important;
        max-width: 400px !important;
    }
}