@import "@assets/styles/utils/colors";

.WeatherCard {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px;
    border-radius: 6px;
    height: 100%;
    background-color: rgba(26, 26, 26, 0.612);

    color: white;

    h3 {
        text-align: center;
    }
}
