@import "@assets/styles/utils/colors";

.acordionSummary {
  border-radius: 4px;
  border-left: 6px solid black;
  background: $secondary-200;
  font-weight: bold;
  color: $secondary-700;
  box-shadow: none;

  &[step-flow="completed"] {
    border-color: $completed;
  }

  &[step-flow="pending"] {
    border-color: $pending;
  }

  &[step-flow="review"] {
    border-color: $in-revision;
  }

  &[step-flow="canceled"] {
    border-color: $red;
    color: $red;
  }

  &[step-flow="notCompleted"],
  &[step-flow="deactivated-phasejump"] {
    border-color: $not-started;
  }

  .summary {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    .phaseAction {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }

  &.dark {
    background: $secondary-dark-600;
    color: $white;
  }
}

.taskContainer {
  padding: 0 0.5rem !important;

  &:first-child {
    margin-top: 0.5rem !important;
  }

  .task {
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &[step-task="notCompleted"] {
      .icon {
        color: $not-started;
      }
    }

    &[step-task="waiting-review"] {
      .icon {
        color: $awaiting-review;
      }
    }

    &[step-task="review"] {
      .icon {
        color: $in-revision;
      }
    }


    &[step-task="completed"] {

      .icon {
        color: $completed;
      }
    }

    &[step-task="pending"] {
      background: $light-orange-50;

      .icon {
        color: $pending;
      }
    }
  }

  &.dark {
    background: $secondary-dark-600;

    .task {

      &[step-task="completed"],
      &[step-task="notCompleted"] {
        color: $white;
      }
    }
  }
}