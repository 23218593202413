@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";

.headerFilter {
  @include flexCenter;
  justify-content: space-between;

  button {
    width: 100%;
    max-width: 120px;
  }
}

.tasksList {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;

  .header {
    @include flexCenter;
    padding: 0.2rem 1rem;
    border-radius: 4px 4px 0 0;
    background-color: $dark-blue;
    justify-content: space-between;

    svg {
      color: $white;
    }

    h4 {
      font-weight: bold;
      color: $white;
    }

  }

  .headerFilterResults {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .messageNotTasks {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;

    p {
      font-size: 20px;
      font-weight: bold;
      color: $medium-blue;
    }
  }

  .listCardsTasks {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
  }
}

.gabaritTypes {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-left: 1rem;

  .type {
    display: flex;
    gap: 0.5rem;
    font-size: 14px;
  }
}

@media screen and (max-width: 850px) {
  .listCardsTasks {
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr)) !important;
  }

  .gabaritTypes {
    flex-direction: column;
    align-items: start;
    gap: 0.5rem;
  }
}

@media screen and (max-width: 400px) {
  .listCardsTasks {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
  }
}