@import "@assets/styles/utils/colors";

.FileList {
    $width-delay: 0.5s;

    display: flex;
    width: 100%;

    &Left,
    &Right,
    &Slider {
        transition: width $width-delay ease-in-out,
            margin-left $width-delay ease-in-out,
            margin-right $width-delay ease-in-out;
    }

    &Left {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 8px;
        width: 100%;
    }

    &Right {
        width: 0;

        &[data-open="true"] {
            width: 70%;
        }
    }

    &Section {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        margin-bottom: 1rem;
        padding-left: 0.3rem;
        h3 {
            color: $dark-text;
        }
    }

    &Slider {
        width: 100%;
        margin-bottom: 1rem;
        .fileCard {
            width: 100%;
            max-width: 150px;
        }
    }

    &Cards {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;

        .areaCards {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
            gap: 8px;
            width: 100%;
        }
    }

    &Message {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150px;
        width: 100%;

        p {
            font-size: 20px;
            font-weight: bold;
            color: $medium-blue;
        }
    }

    &Pagination {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 10px;
    }
}
.modal{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem ;

    .contentModal{
        overflow: auto;
    }
}
@media screen and (max-width: 768px){
    .modal{
        padding-bottom: 3rem;
        align-items: normal;
        
        .contentModal{
            margin-bottom: 1rem;
            border-radius: 8px;
        }
    }
}