@import "@assets/styles/utils/colors";

.ExecutedDocument {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 5px;
    padding: 5px;
}

.TaskDocument {
    display: flex;
    flex-direction: column;
    gap: 10px;

    button {
        margin-top: 40px;
    }
}

.DropZone {
    width: 100%;
    border: 1px dashed $dark-blue;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 10px;

    input {
        display: none;
    }

    label {
        font-weight: bold;
        color: $dark-blue;
        cursor: pointer;
    }
}

.File {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid $light-blue;
    padding: 8px;
}

.FileTop {
    display: flex;
    justify-content: space-between;
}

.FileTopLeft {
    display: flex;
    gap: 10px;
    align-items: center;

    svg {
        width: 25px;
        height: 25px;
        color: $light-blue;
    }
}

.FileTopRight {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    cursor: pointer;

    svg {
        width: 15px;
        height: 15px;
        color: $light-text;
    }
}

.buttons {
    display: flex;
    gap: 0.5rem;

    button {
        height: 35px;
        font-size: 18px;
    }
}