@import "@assets/styles/utils/colors";

.MuiModal-root {
    display: flex;
    align-items: center;
    justify-content: center;

    .RichTextModal {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 8px;
        width: 80vw;
        background-color: white !important;
        border: 2px solid $medium-blue;
        border-radius: 10px;
        padding: 10px;
    }

    .RichTextModalTop {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .ql-editor {
        height: 500px;
    }

    @media screen and (max-width: 480px) {
        .ql-editor {
            height: 300px;
        }
    }
}
