@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";

.areaCircle {
  @include flexCenter;
  gap: 0.2rem;

  .circle {
    padding: 8px;
    border-radius: 200px;
    background: $dark-gray;
  }

  &.documentInput {
    .circle {
      &:last-child {
        background: $dark-green;
      }
    }
  }

  &.formInput {
    .circle {
      &:nth-child(2) {
        background: $dark-blue;
      }
    }
  }

  &.approval {
    .circle {
      &:first-child {
        background: $red;
      }
    }
  }

  &.dark{
    .circle{
      background: $secondary-dark-700;
    }
  }

}