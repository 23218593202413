@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";

.inboxContainer {
    padding: 40px 1rem;
    margin-right: 1rem;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.InboxAside {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex: 1;
}

.InboxContent {
    display: flex;
    gap: 1rem;
}

@media screen and (max-width: 1400px) {
    .detailFlow {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 99;

        padding-left: 2rem;

        background-color: #00000042;

        display: flex;
        justify-content: end;
    }
}

@media screen and (max-width: 768px) {
    .inboxContainer {
        margin-right: 0rem;
    }
}