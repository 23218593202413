@import "@assets/styles/utils/colors";

.Topbar {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    border-radius: 8px;
    border-color: $dark-gray;
    border-width: 1px;
    border-style: solid;
    background-color: $white;

    min-height: 50px;
    padding: 0 20px;

    &Right {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }

    &Left {
        justify-content: flex-start;
        flex: 1;
    }

    &Right {
        justify-content: flex-end;
    }

    &UserPhoto {
        $photo-size: 40px;
        width: $photo-size;
        height: $photo-size;
        font-size: 12px;

        &Button {
            padding: 0 !important;
        }
    }

    &Languages,
    &UserMenu {
        top: 95px !important;
    }

    &Search {
        *::before {
            display: none;
        }
    }

    &Option {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        img {
            border-radius: 50%;
            width: 20px;
            height: 20px;
        }
    }


    &.dark{
        background: $secondary-dark-600;
        border-color: $dark-blue;
        color: $dark-text;

        .TopbarRight{
            button{
                color: #f0f0f0;
            }
        }
    }
}

