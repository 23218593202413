@import "@assets/styles/utils/colors";

.WeatherInfo {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}

.WeatherInfoList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
