@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/animations";

$time: 0.5s;

.PageSpinner {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100000;
    width: 100vw;
    height: 0;
    background-color: $dark-black;
    transition: height $time linear;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.Logo {
    transition: height $time linear;
}
