@import "@assets/styles/utils/colors";

.FilePanel {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;
    border-radius: 8px;
    width: 100%;
    padding-top: 0.5rem;

    &Title {
        color: $dark-black;
        font-weight: 600;
    }

    &Section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0px;
        width: 100%;
        margin: 10px 0;
    }

    &Name {
        color: $dark-black;
        font-weight: 600;
        margin: 0;
        font-size: 16px;
    }

    &Value {
        color: $light-text;
        font-weight: 400;
        margin: 0;
        font-size: 12px;
    }

    &Accesses {
        width: 100%;
        margin: 10px 0;
    }

    &.dark{

        .FilePanelName{
            color: $white;
        }
    }
}
