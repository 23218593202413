@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";



.header {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
}

.optionsFilter {
    padding-top: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .inputs {
        display: flex;
        gap: 1rem;
    }

    .cheboxArea {
        display: flex;
        align-items: center;
        width: 100%;

        button {
            flex: 1;
        }
    }
}

.taskList {
    display: flex;
    flex-direction: column;
    gap: 6px;
    overflow-y: scroll;
    width: 100%;
}


.contentMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    background: $white;
    border-radius: 1rem;
    padding: 1rem;

    .headerMessage {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
    }
}


@media screen and (max-width: 600px) {


    .optionsFilter {
        .inputs {
            flex-direction: column;
        }

        .cheboxArea {
            display: grid !important;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        }
    }


}