@import "@assets/styles/utils/colors";

.filterAdmContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: $dark-text;
}

.searchSelectArea {
  display: flex;
  gap: 1rem;
  width: 100%;
}

@media screen and (max-width: 840px) {
  .searchSelectArea {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .searchSelectArea {
    flex-direction: row;
  }
}

@media screen and (max-width: 580px) {
  .searchSelectArea {
    flex-direction: column;
  }
}