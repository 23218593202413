@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";

.FileCard {
    $card-height: 100px;
    $card-width: 120px;
    $card-padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    width: 100%;
    max-width: 150px;

    border: 1px solid $dark-gray;

    border-radius: 5px;
    background-color: $white;
    padding: $card-padding;
    transition: background-color 0.5s ease-in-out;

    &[data-selectable="true"] {
        cursor: pointer;

        &:hover,
        &[data-selected="true"] {
            background-color: $dark-gray;
        }
    }

    &Header {
        display: flex;
        align-items: center;
        justify-content: space-around;

        height: calc(#{$card-height}/ 2);
        width: 100%;
    }

    &Icon {
        height: 100%;
        width: 50%;
    }

    &Info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 8px;

        p {
            margin: 0;
            word-break: break-all;
            text-align: center;
        }
    }

    &Filename {
        font-size: 16px;
        color: $dark-text;
        font-weight: 600;
        @include OverflowEllipsis(1);
    }

    &FileDescription {
        font-size: 14px;
        color: $light-text;
        font-weight: 500;
        @include OverflowEllipsis(1);
    }

    &Options {
        padding: 5px 15px !important;
    }


    &.dark {
        background: $secondary-dark-600;
        border-color: $dark-blue;

        &:hover{
            background: $secondary-dark-800;
        }
    }
}