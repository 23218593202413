@mixin OverflowEllipsis($lines) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem
}

@mixin iconStyles($background) {
    background: $background;
    color: $white;

    &.notIcon {
        background: $background;
    }
}