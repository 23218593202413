@import "@assets/styles/utils/colors";

.label {
    display: flex;
    width: 100%;
    background-color: $dark-gray;
    border-radius: 0.3rem;
    overflow: hidden;
    border: 2px solid $dark-blue;
    transition: all 0.3s;

    &:hover {
        border-color: $dark-blue-900;

        span {
            background: $dark-blue-900;
        }
    }

    span {
        background-color: $dark-blue;
        font-size: 20px;
        padding: 0.8rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer;

        transition: all 0.3s;
    }

    input {
        width: 100%;
        border: none;
        background-color: transparent;
        margin-left: 1rem;
        font-size: 1rem;
        padding-right: 1rem;

        text-overflow: ellipsis;
        outline: none;

    }

    &.dark{
        background: $secondary-dark-600;
        input{

            color: #f0f0f0;

            &::placeholder{
                color: #f9f9f999;

            }
            
        }
    }
}