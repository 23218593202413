@import "@assets/styles/utils/colors";

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

button {
    outline: none;
    border: none;
    cursor: pointer;
}

body,
html,
#root {
    height: 100%;
}

body {
    position: relative;
    background-color: $light-gray;
    font-family: "Lato", sans-serif;
}

body.dark{
    background-color: $secondary-dark-900;
    font-family: "Lato", sans-serif !important;
}

a {
    cursor: pointer;
}
