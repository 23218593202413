@import "@assets/styles/utils/colors";

.TaskForm {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;

    hr {
        width: 100%;
        height: 1px;
        border: 0;
        background-color: $gray-400;
    }

    p {
        margin: 0;
    }
}
