@import "@assets/styles/utils/colors";

.Weather {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 40px 1rem;
    padding-right: 2rem;
    width: 100%;
}
.WeatherHeader {
    display: flex;
    justify-content: space-between;
    background-color: $dark-blue;
    padding: 10px;
    padding-bottom: 20px;
    border-radius: 6px;

    * {
        color: #fff;
    }
}

.WeatherError {
    display: flex;
    flex-direction: column;
    height: 400px;
}

.WeatherErrorMessage {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    margin: auto;
    max-width: 600px;
    width: 100%;

    svg {
        height: 80px;
        width: 80px;
        color: $dark-blue;
    }
}

.WeatherHeaderLeft {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.WeatherHeaderLeftLast {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.WeatherHeaderRight {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        font-size: 18px;
    }
}

@media screen and (max-width: 500px) {
    .WeatherHeader {
        flex-direction: column;
    }

    .WeatherHeaderLeft {
        gap: 10px;
    }

    .WeatherErrorMessage {
        flex-direction: column;
    }
}
