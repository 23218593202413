@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";

.Workflows {
    width: 100%;

    &NothingFound {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150px;

        p {
            font-size: 20px;
            font-weight: bold;
            color: $medium-blue;
        }
    }

    &.dark{
        .WorkflowCardList{
            .WorkflowCardInfo{
                background: $secondary-dark-600;
                border-color: $dark-blue;
            }
        }

        strong{
            color: $white;
        }
    }
}

.WorkflowCardList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 180px));

    margin: 1rem 0;
    gap: 1rem;
}

.WorkflowCardInfo {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    background-color: $white;
    border: 2px solid $dark-gray;
    border-radius: 15px;
    height: 180px;
    width: 180px;
    padding: 1rem;

    &Title {
        color: $light-text;
        margin-top: 0.5rem;
        text-align: center;
        font-size: 16px;
        @include OverflowEllipsis(3);
    }

    .bannerIcon {
        height: 60px;
        width: 70px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &Img {
        width: 100%;
        max-width: 60px;
        object-fit: contain;
    }


    svg {
        margin-top: 2.5rem;
        width: 50px;
        height: 50px;
        color: $medium-blue;
    }
}

.Modal {
    display: flex;
    align-items: center;
    justify-content: center;
}