@import "@assets/styles/utils/colors";

.Input {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    &Label {
        width: 100%;
    }

    textarea {
        border: 1px solid $gray-400;
        border-radius: 5px;
        background-color: transparent;
        width: 100%;
        padding: 12px;
        outline: none;
        font-size: 16px;

        &:focus {
            outline: 1.8px solid $dark-blue;
        }
    }

    input[type="color"] {
        width: 40px;
        height: 40px;
    }
}
