@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";

.table {
    border: 2px solid $dark-blue;

    .tHead {
        background: $light-blue;
        border-bottom: 2px solid $dark-blue;

        .title {
            color: $white;
            font-weight: bold;

            &+.title {
                border-left: 2px solid $dark-blue;
            }
        }
    }

    .colunmCancel {
        color: $red;
        font-weight: bold;
    }

    .tFooter {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-left: 15px;
    }

    .row {
        transition: all 0.3s;
        cursor: pointer;

        .tableIcon {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        &:hover {
            background: $dark-gray;
        }
    }

    .notFoundFlow {
        display: flex;
        padding: 1rem;
        width: 100%;
        text-align: center;
        color: $dark-blue;
    }

    &.dark {
        background: $secondary-dark-600;
        color: #f5f5f599;

        .tHead {
            background: $secondary-dark-900;
        }

        .row {

            &:hover {
                background: $secondary-dark-800;
            }
        }

        .colunm {
            color: #f5f5f599;
        }
    }
}

@media screen and (max-width: 700px) {
    .table {
        .tHead {
            .title {
                padding: 0.5rem !important;
                font-size: 12px !important;
            }
        }
    }
}