@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";

.detailTaskContainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;

    padding-left: 2rem;

    background-color: #00000042;

    display: flex;
    justify-content: end;
    .detailTask {
        background: $white;
        width: 100%;
        max-width: 750px;
        padding: 1.5rem;
        position: relative;

        .floatButton {
            position: absolute;
            top: 150px;
            left: -20px;
            @include flexCenter;
            flex-direction: column;

            button {
                @include flexCenter;
                width: 40px;
                height: 40px;
                background: $dark-blue;
                color: $white;

                font-size: 18px;
                transition: all 0.3s;

                &:hover {
                    background: $dark-blue-900;
                }
            }
        }

        .infoTask {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            height: 100%;
            overflow-y: auto;

            .headerButton {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 1rem;

                button {
                    white-space: nowrap;
                }
            }

            .headerButtons {
                display: flex;
                align-items: center;
                gap: 1rem;
                .areaCopy{
                    position: relative;

                    .copyText{
                        font-size: 12px;
                        position: absolute;
                        bottom: -20px;
                        background: #5f5f5f;
                        color: #FFF;
                        border-radius: 2px;
                        padding: 2px;
                        white-space: nowrap;
                    }
                }

                button {
                    height: 45px;
                    font-size: 18px !important;
                }
            }

            .headerInfoTask {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                align-items: start;

                h1 {
                    font-size: 1.1rem;
                }

            }

            .details {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                .titleTask {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    margin-top: 1rem;

                    span {
                        padding: 0.5rem;
                        font-size: 18px;
                        background: $gray-100;
                        border-radius: 200px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    strong {
                        @include OverflowEllipsis(1)
                    }

                }

                .tableItem {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    strong {
                        background: $gray-200;
                    }

                    p,
                    strong {
                        min-width: 100%;
                        padding: 0.3rem 0.5rem;

                    }

                    p {
                        background: $gray-50;
                    }
                }
            }
        }

        &.dark{
            background: $secondary-dark-900;
            .details{


                .titleTask {

                    span {
                       color: $white;
                       background: $dark-blue;
                    }

                    strong {
                        @include OverflowEllipsis(1)
                    }

                }

                .tableItem{
                    strong{
                        background: $secondary-dark-700;
                    }
                    p{
                        background: $secondary-dark-600;
                    }
                }
            }


        }
    }
}

@media screen and (max-width: 1400px) {
    html:has(.detailTaskContainerOpen) {
        overflow: hidden;
    } 
}

@media screen and (max-width: 768px) {

    .detailTaskContainer {
        margin-right: 0rem;
        padding-bottom: 3rem;
    }
}

@media screen and (max-width: 468px) {
    .headerButton {
        flex-direction: column-reverse;

        section {
            flex-direction: column;
            width: 100%;

            button {
                width: 100%;
            }
        }
    }
}