@import "@assets/styles/utils/colors";
@import "@assets/styles/utils/mixins";

.icon {
  @include flexCenter;

  padding: 0.5rem;
  border-radius: 200px;
  font-size: 18px;
  width: 10px;

  &.hasIcon {
    width: 30px;
    height: 30px;
  }

  &.pending {
    @include iconStyles($light-orange);
  }

  &.completed {
    @include iconStyles($dark-green);
  }

  &.review {
    @include iconStyles($dark-orange);
  }

  &.waiting-review {
    @include iconStyles($in-revision);
  }

  &.removed {
    @include iconStyles($red);
  }
}