@import "@assets/styles/utils/colors";
.sidebar {
    background: $dark-blue;

    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;

    padding: 0.5rem 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    z-index: 9999;

    .button {
        color: #fff;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 22px;

        display: flex;
        align-items: center;
        justify-content: center;

        padding: 0.5rem;
        border-radius: 4px;

        cursor: pointer;

        &[data-active="true"] {
            background-color: #fff;

            svg {
                color: $dark-blue;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .sidebar {
        display: none;
    }
}
