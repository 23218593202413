@import "@assets/styles/utils/colors";

.RichText {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    &Top {
        display: flex;
        justify-content: space-between;
        width: 100%;

        svg {
            width: 20px;
            height: 20px;
            color: $dark-blue;

            &:hover {
                transition: all 0.3s;
                color: $medium-blue;
            }
        }
    }

    &Label {
        width: 100%;
        font-weight: 500;
        font-size: 18px;
    }

    &Display {
        border: 1px solid $gray-400;
        border-radius: 5px;
        padding: 10px;
        width: 100%;
    }
}

@media screen and (max-width: 1400px) {
    .RichText {
        margin-bottom: 30px;
    }
}
