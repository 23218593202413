@import "@assets/styles/utils/colors";

.modalContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  .step {
    border-left: 8px solid $light-blue;
    box-shadow: 2px 2px 4px #1212122c;
    padding: 0.5rem;

    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

  }

  &.dark{
    .step{
      background: $secondary-dark-600;
    }
  }
}